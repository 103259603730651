<template>
    <div class="leftArea">
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="500px"></Echart>
        <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="490px"></Echart>
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            options1: {},
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
               this.setOption(newData)
               this.setMineOption(newData)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {
        setOption(data) {
            this.options = {
                title: {
                    text: '订单服务时长分布',
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'item',
                },
                toolbox: {
                    top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        saveAsImage: { show: true }
                    }
                },
                series: [{
                    type: 'pie',
                    radius: [40, 100],
                    // top: top + '%',
                    // height: '33.33%',
                    left: 'center',
                    width: 500,
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        alignTo: 'edge',
                        formatter: '{name|{b}} {time|{c} 单} \n {per|{d}%}',
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        fontSize: 14,
                        rich: {
                            per: {
                                padding: [10, 0, 0, 0],
                            },
                        }
                    },
                    labelLine: {
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: (params) => {
                        const myChart = this.$refs.centreLeft1ChartRef.chart
                        const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                        const points = params.labelLinePoints;
                        // Update the end point.
                        points[2][0] = isLeft
                          ? params.labelRect.x
                          : params.labelRect.x + params.labelRect.width;
                        return {
                          labelLinePoints: points
                        };
                    },
                    data: data
                }]
            }
        },
        setMineOption(data) {
            this.options1 = {
                // title: {
                //     text: "订单服务时长分布",
                //     left: 'center',
                //     // top: 10,
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: ['#73c0de'],
                toolbox: {
                    // top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: data.map(item => item.name),
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: [{
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} 单'
                    }
                }, ],
                series: [{
                        name: '订单数量',
                        type: 'bar',
                        label: {
                            show: !0,
                        },
                        // markLine: {
                        //     data: [{ type: 'average', name: '平均线' }]
                        // },
                        data: data.map(item => item.value),
                    },
                    // {
                    //     name: '活跃人数',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     smooth: !0,
                    //     symbolOffset: [0, -20],
                    //     symbolSize: 18,
                    //     tooltip: {
                    //         valueFormatter: function(value) {
                    //             return value + ' 人';
                    //         }
                    //     },
                    //     data: Object.values(data)
                    // }
                ]
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.leftArea {
    position: relative;
    height: 100%;
    background: #0F0C28;
}
</style>