<template>
    <div class="body-box" v-if="!loading">
        <div class="left-box">
            <leftArea :data="list.timeData" />
        </div>
        <div class="center-box">
            <div id="centerTop">
                <dv-border-box-7>
                    <div class="tNumber">
                        当前订单平均服务时间：<sapn class="prefix">{{list.timeAvg}}</sapn>小时
                    </div>
                </dv-border-box-7>
            </div>
            <!-- <centerMap :data="list.consumeData" @change="onDateChange" /> -->
        </div>
        <div class="right-box">
            <rightArea :data="list.userCmt" />
        </div>
    </div>
</template>
<script>
// import centerMap from './service/map'
import leftArea from './service/left'
import rightArea from './service/right'

import { service } from '@/api/index'

export default {
    data() {
        return {
            list: {
                timeData: [],
                userCmt: [],
                // userData: [],
            },
            loading: !0,
        }
    },
    components: {
        // centerMap,
        leftArea,
        rightArea
    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            service({}, (res) => {
                this.list = res
                this.loading = !1
            })
        },
        onDateChange(type) {
            service({ type }, (res) => {
                this.list = res
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.body-box {
    grid-template-columns: 4fr 4fr 4fr !important;
}

#centerTop {
    .tNumber {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;

        .prefix {
            color: #3de7c9;
            font-size: 40px;
        }
    }
}
</style>