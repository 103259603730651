<template>
    <div class="body-box" v-if="!loading">
        <div class="left-box">
            <leftArea :data="list.data[0]"/>
        </div>
        <div class="left-box">
            <leftArea :data="list.data[1]"/>
        </div>
        <div class="left-box">
            <leftArea :data="list.data[2]"/>
        </div>
        <!-- <div class="center-box">
            <div id="centerTop">
                <dv-border-box-7>
                    <div class="tNumber">
                       客户地理、消费水平分布
                    </div>
                </dv-border-box-7>
            </div>
            <centerMap :data="list.consumeData" @change="onDateChange" />
        </div> -->
        <!-- <div class="right-box">
             <rightArea :data="list.userData" />
        </div> -->
    </div>
</template>

<script>
// import centerMap from './trend/map'
import leftArea from './trend/left'
// import rightArea from './trend/right'

import { trend } from '@/api/index'

export default {
    data() {
        return {
            list: {
                userTag: [],
                consumeData: [],
                userData: [],
            },
            loading: !0,
        }
    },
    components: {
        // centerMap,
        leftArea,
        // rightArea
    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            trend({}, (res) => {
                this.list = res
                this.loading = !1
            })
        },
        onDateChange(type) {
            trend({type}, (res) => {
                this.list = res
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.body-box {
    grid-template-columns: 5fr 4fr 5fr!important;
}
#centerTop {
    .tNumber {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
    }
}
</style>