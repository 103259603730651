<template>
    <div class="leftArea">
        <div class="filter">
            <div class="item" v-for="item in orders" :key="item.name">
                <dv-border-box-12>{{item.name}}: {{item.value}}</dv-border-box-12>
            </div>
        </div>
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="410px">
        </Echart>
         <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="420px">
        </Echart>
        <div class="ranking">
            <dv-capsule-chart :config="ranking" style="width:300px;height:220px;" />
        </div>
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            ranking: {
                data: [{
                        name: '南阳',
                        value: 167
                    },
                    {
                        name: '周口',
                        value: 67
                    },
                    {
                        name: '漯河',
                        value: 123
                    },
                    {
                        name: '郑州',
                        value: 55
                    },
                    {
                        name: '西峡',
                        value: 98
                    }
                ],
                showValue: !0,
            },
            options: {},
            options1: {},
        }
    },
    props: {
        orders: {
            type: Array,
            default: () => [],
        },
        complete: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        orders: {
            handler(newData) {
                console.log(newData)
                // this.setOptions(newData)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        },
        complete: {
            handler([data, ydata]) {
                let xAxis1 = []
                let xAxis2 = []

                let rt1 = []
                let rt2 = []
                let rv1 = []
                let rv2 = []
                data.forEach((v) => {
                    rt1.push({
                        name: v.name,
                    })
                    xAxis1.push(v.name)
                    rv1.push(v.value)
                })
                ydata.forEach((v) => {
                    rt2.push({
                        name: v.name,
                    })
                    xAxis2.push(v.name)
                    rv2.push(v.value)
                })
                this.options = {
                    title: {
                        text: '近一周订单完成数',
                        left: 'center',
                        top: 10,
                    },
                    tooltip: {},
                    toolbox: {
                        feature: {
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['line', 'bar'] },
                            saveAsImage: { show: true }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxis1
                    },
                    yAxis: {
                        type: 'value'
                    },
                    radar: [
                        {
                            indicator: rt1,
                            center: ['50%', '32%'],
                            radius: 65
                        },
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {},
                            data: [{
                                value: rv1,
                                name: '近一周订单完成数'
                            }]
                        },
                        {
                            data: rv1,
                            type: 'bar',
                            name: '订单数',
                            markLine: {
                                data: [{ type: 'average', name: '平均线' }]
                            },
                            label: {
                                show: !0,
                                fontSize: 18, color: '#000'
                            },
                            itemStyle: {opacity: 0.5}
                        }
                    ]
                }
                this.options1 = {
                    title: {
                        text: '近一年订单完成数',
                        left: 'center',
                        top: 10,
                    },
                    toolbox: {
                        feature: {
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['line', 'bar'] },
                            saveAsImage: { show: true }
                        }
                    },
                    tooltip: {},
                    color: ['#585eaa'],
                    xAxis: {
                        type: 'category',
                        data: xAxis2
                    },
                    yAxis: {
                        type: 'value'
                    },
                    // radar: [
                    //     {
                    //         indicator: rt2,
                    //         center: ['50%', '32%'],
                    //         radius: 65
                    //     }
                    // ],
                    series: [
                        // {
                        //     type: 'radar',
                        //     tooltip: {
                        //         trigger: 'item'
                        //     },
                        //     areaStyle: {},
                        //     name: 'Precipitation',
                        //     data: [{
                        //         name: '近一年订单完成数',
                        //         value: rv2
                        //     }]
                        // },
                        {
                            data: rv2,
                            type: 'bar',
                            name: '订单数',
                            markLine: {
                                data: [{ type: 'average', name: '平均线' }]
                            },
                            label: {
                                show: !0,
                                // fontSize: 14, color: '#000'
                            },
                            // itemStyle: {opacity: 0.5}
                        }
                    ]
                }
                // this.setOptions(newData)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {

    },
};
</script>
<style lang="scss" scoped>
.leftArea {
    position: relative;
    height: 100%;
    background: #0F0C28;
    // .ranking {
    //     position: absolute;
    //     top: 400px;
    //     left: 40px;
    // }
    .filter {
        // position: absolute;
        // top: 20px;
        // right: 20px;
        // width: 350px;
        min-height: 160px;
        padding-top: 20px;
        // justify-content:center;
        display: flex;
        flex-wrap: wrap;
        z-index: 99;
        .item {
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: #eee;
            font-size: 18px;
            // padding: 0 50px;
            width: 150px;
            margin: 0 5px;
            // margin-left: 20px;
            // margin-bottom: 20px;
        }
    }
}
</style>