<template>
    <div class="leftArea">
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="990px"></Echart>
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
                let title = []
                let series = []

                newData.map(function(data, idx) {
                    // var top = idx * 20;
                    let m = Math.ceil((idx + 1) % 2) ? 1 : 3
                    let left = 25 * m
                    let n = Math.ceil((idx + 1) / 2) - 1
                    let map = [14, 38, 62, 86]
                    let top = map[n]
                    title.push({
                        subtext: data.name,
                        left: `${left}%`,
                        top: `${top - 14}%`,
                        textAlign: 'center',
                        subtextStyle: {
                            color: '#999',
                            fontWeight: 'normal',
                            fontSize: 18
                        }
                    })
                    series.push({
                        type: 'pie',
                        radius: [30, 65],
                        center: [`${left}%`, `${top}%`],
                        // top: top + '%',
                        // height: '20%',
                        // left: 'center',
                        // width: 400,
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            formatter: '{name|{b}}\n {per|{d}%}',
                            alignTo: 'labelLine',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                per: {
                                    fontSize: 14,
                                    color: '#999'
                                }
                            }
                        },
                        data: data.list
                    })
                })
                this.options = {
                    title: title,
                    series: series,
                };
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {

    },
};
</script>
<style lang="scss" scoped>
.leftArea {
    position: relative;
    height: 100%;
    background: #0F0C28;
}
</style>