<template>
    <div>
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="550px"></Echart>
        <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="440px"></Echart>
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            options1: {},
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler([data, mdata]) {
                this.setOption(data)
                this.getMineOption(mdata)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {},
    methods: {
        setOption([a, b, c, d]) {
            this.options = {
                title: {
                    text: "会员增长趋势",
                    left: 'center',
                    top: 0,
                },
                toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        saveAsImage: { show: true },
                    }
                },
                legend: {
                    top: 30,
                    // right: 0,
                    // orient: 'vertical',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: [
                    { left: '10%', top: '12%', width: '38%', height: '38%' },
                    { right: '5%', top: '12%', width: '38%', height: '38%' },
                    { left: '10%', bottom: '5%', width: '38%', height: '38%' },
                    { right: '5%', bottom: '5%', width: '38%', height: '38%' }
                ],
                xAxis: [
                    { gridIndex: 0, type: 'category', data: Object.keys(a) },
                    { gridIndex: 1, type: 'category', data: Object.keys(b) },
                    { gridIndex: 2, type: 'category', data: Object.keys(c) },
                    { gridIndex: 3, type: 'category', data: Object.keys(d) },
                ],
                yAxis: [
                    { gridIndex: 0, type: 'value' },
                    { gridIndex: 1, type: 'value' },
                    { gridIndex: 2, type: 'value' },
                    { gridIndex: 3, type: 'value' }
                ],
                series: [{
                        name: '普通会员',
                        type: 'line',
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        data: Object.values(a),
                        smooth: !0,
                        label: {
                            show: !0,
                        },
                    },
                    {
                        name: '白银会员',
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        data: Object.values(b),
                        smooth: !0,
                        label: {
                            show: !0,
                        },
                    },
                    {
                        name: '黄金会员',
                        type: 'line',
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        data: Object.values(c),
                        smooth: !0,
                        label: {
                            show: !0,
                        },
                    },
                    {
                        name: '铂金会员',
                        type: 'line',
                        xAxisIndex: 3,
                        yAxisIndex: 3,
                        data: Object.values(d),
                        smooth: !0,
                        label: {
                            show: !0,
                        },
                    },
                ]
            }
        },
        getMineOption(data) {
            this.options1 = {
                title: {
                    text: "会员活跃趋势",
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: ['#73c0de'],
                toolbox: {
                    top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: Object.keys(data),
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: [{
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 人'
                        }
                    },
                ],
                series: [
                    {
                        name: '活跃人数',
                        type: 'bar',
                        label: {
                            show: !0,
                        },
                        markLine: {
                            data: [{ type: 'average', name: '平均线' }]
                        },
                        data: Object.values(data)
                    },
                    // {
                    //     name: '活跃人数',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     smooth: !0,
                    //     symbolOffset: [0, -20],
                    //     symbolSize: 18,
                    //     tooltip: {
                    //         valueFormatter: function(value) {
                    //             return value + ' 人';
                    //         }
                    //     },
                    //     data: Object.values(data)
                    // }
                ]
            };
        },
    },
};
</script>