<template>
    <div class="m-right">
        <dv-border-box-7 style="width: 96%;margin: 0 auto;">
            <div class="tNumber">
                <div class="prefix">服务中： </div>
                <div class="prefix" style="font-size:70px;height:100px;line-height:100px; ">{{total}} </div>
                <div class="prefix">个订单 </div>
            </div>
        </dv-border-box-7>

        <div class="mlist">
            <div v-for="item, idx in mdata" :key="idx" @click="onShowMark(idx)">
                <dv-border-box-8 class="mitem" v-if="index == idx">{{item.name}}</dv-border-box-8>
                <dv-border-box-7 class="mitem" v-else>{{item.name}}</dv-border-box-7>
            </div>
        </div>
        <div class="m-marks">
            <div class="marks" v-for="m,ix in marks" :key="ix">
                <dv-border-box-12 style="min-height: 110px;">
                    <div>
                        <div style="margin-top: 6px;">服务人员：<span style="color:#999;">{{m.name}}</span></div>
                        <div style="margin: 10px 0;">服务地址：<span style="color:#999;">{{m.address}}</span></div>
                        <div>服务内容：<span style="color:#999;">{{m.goods.join(',')}}</span></div>
                        <!-- <div class="goods" v-for="d,i in m.goods" :key="i">{{d}}</div> -->
                    </div>
                </dv-border-box-12>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
            total: 0,
            marks: [],
            mdata: [],
            index: 0,
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
                this.setOptions(newData)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {
        setOptions(data) {
            let marks = []
            let mdata = []
            data.forEach((it) => {
                if (it.marks.length > 0) {
                    mdata.push(it)
                    marks = marks.concat(it.marks)
                }
            })
            this.mdata = mdata
            this.marks = mdata[0]['marks']
            this.total = marks.length
            this.numberData = {
                number: [marks.length],
                content: '{nt}',
                formatter: (number) => {
                    const data = number.toString().split('.')
                    const numbers = data[0].toString().split('').reverse()
                    const segs = []
                    while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                    let mnumber = segs.join(',').split('').reverse().join('')
                    if (data[1]) {
                        mnumber += '.' + data[1]
                    }
                    return mnumber
                },
                textAlign: 'left',
                style: {
                    fontSize: 70,
                    fill: 'rgb(238,102,102)'
                }
            }
        },
        onShowMark(index) {
            this.index = index
            let { mdata } = this
            console.log(mdata)
            this.marks = mdata[index]['marks']
        }
    },
};
</script>

<style lang="scss" scoped>
.tNumber {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tNumber-box {
        height: 100%;
    }
    .prefix {
        height:1px;
        color: rgb(238,102,102);
        font-size:30px;
        margin-right: 12px;
    }
}
.mlist {
    padding: 0 10px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 180px);
    gap: 15px 15px;
    max-height: 355px;
    overflow-y: auto;
    .mitem {
        font-size: 20px;
        height: 50px; 
        width: 180px; 
        line-height: 50px; 
        text-align: center;
    }
}
.m-marks {
    padding: 0 10px;
    margin-top: 20px;
    max-height: 410px;
    overflow-y: auto;
    .marks {
        font-size: 18px;
        margin-bottom: 10px;
        .goods {
            color: #999;
            text-indent: 20px;
            margin: 10px 0;
        }
    }
}
</style>
<style type="text/css">
    .m-marks .dv-border-box-12 .border-box-content {
        display: flex;
        padding: 10px 15px;
/*        justify-content: flex-start;
        align-items: center;
        flex-direction: column;*/
    }
</style>