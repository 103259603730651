<template>
    <div class="leftArea">
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="550px"></Echart>
        <!-- <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="440px"></Echart> -->
        <!-- <dv-scroll-board :config="config" style="width:590px;height:400px" /> -->
        <dv-scroll-ranking-board :config="config" style="width:590px;height:400px" />
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            options1: {},
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler([data, mdata]) {
                this.setOption(data)
                this.config = { 
                    data: mdata,
                    valueFormatter ({ value }) {
                        console.log(value)
                        return ''
                    },
                    index: true,
                }
                // this.getMineOption(mdata)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {},
    methods: {
        setOption(data) {
            this.options = {
                title: {
                    text: "服务评分分布",
                    left: 'center',
                    // top: 10,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: ['#73c0de'],
                toolbox: {
                    // top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: data.map(item => '评分：' + item.name),
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: [{
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} 单'
                    }
                }, ],
                series: [{
                        name: '订单数量',
                        type: 'bar',
                        label: {
                            show: !0,
                        },
                        // markLine: {
                        //     data: [{ type: 'average', name: '平均线' }]
                        // },
                        data: data.map(item => item.value),
                    },
                    {
                        type: 'pie',
                        radius: [20, 80],
                        center: ['40%', '40%'],
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            formatter: '评分：{name|{b}} \n {per|{d}%}',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            fontSize: 14,
                            rich: {
                                per: {
                                    padding: [10, 0, 0, 0],
                                },
                            }
                        },
                        labelLine: {
                            length: 25,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        labelLayout: (params) => {
                            const myChart = this.$refs.centreLeft1ChartRef.chart
                            const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                            const points = params.labelLinePoints;
                            // Update the end point.
                            points[2][0] = isLeft
                              ? params.labelRect.x
                              : params.labelRect.x + params.labelRect.width;
                            return {
                              labelLinePoints: points
                            };
                        },
                        data: data
                    }
                ]
            }
        },
        getMineOption(data) {
            this.options1 = {
                title: {
                    text: "会员活跃趋势",
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: ['#73c0de'],
                toolbox: {
                    top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: Object.keys(data),
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: [{
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 人'
                        }
                    },
                ],
                series: [
                    {
                        name: '活跃人数',
                        type: 'bar',
                        label: {
                            show: !0,
                        },
                        markLine: {
                            data: [{ type: 'average', name: '平均线' }]
                        },
                        data: Object.values(data)
                    },
                    // {
                    //     name: '活跃人数',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     smooth: !0,
                    //     symbolOffset: [0, -20],
                    //     symbolSize: 18,
                    //     tooltip: {
                    //         valueFormatter: function(value) {
                    //             return value + ' 人';
                    //         }
                    //     },
                    //     data: Object.values(data)
                    // }
                ]
            };
        },
    },
};
</script>

<style type="text/css">
    .leftArea .dv-scroll-ranking-board .ranking-info {
        font-size: 16px;
        line-height: 30px;
    }
</style>