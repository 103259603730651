<template>
    <div>
        <dv-border-box-7 style="width: 96%;margin: 0 auto;">
            <div class="tNumber">
                <div class="prefix">当前用户 </div>
                <div style="min-width:56%;height:100px;">
                    <dv-digital-flop :config="numberData" class="tNumber-box" />
                </div>
            </div>
        </dv-border-box-7>
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="210px"></Echart>
        <div class="store-list" style="height: 280px">
            <div class="list">
                <div class="item carousel-item" v-for="item,idx in stores" :key="idx">
                    <div class="img">
                        <img :src="require(`@/assets/mark0.png`)" />
                    </div>
                    <div class="">
                        <div style="font-size: 22px;">{{item.name}}</div>
                        <div class="addr">{{item.value}}</div>
                    </div>
                </div>
            </div>
        </div>
        <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="400px"></Echart>
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            options1: {},
            stores: [],
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler([total, vip, data, stores]) {
                this.setOption(vip)
                this.getMineOption(data)
                this.stores = stores
                this.numberData = {
                    number: [total],
                    content: '{nt}',
                    formatter: (number) => {
                        const data = number.toString().split('.')
                        const numbers = data[0].toString().split('').reverse()
                        const segs = []
                        while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                        let mnumber = segs.join(',').split('').reverse().join('')
                        if (data[1]) {
                            mnumber += '.' + data[1]
                        }
                        return mnumber
                    },
                    textAlign: 'left',
                    style: {
                        fontSize: 70,
                        fill: 'rgb(238,102,102)'
                    }
                }
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {
        setOption(data) {
            this.options = {
                title: {
                    text: '各级别会员分布',
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'item',
                },
                toolbox: {
                    top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        saveAsImage: { show: true }
                    }
                },
                series: [{
                    type: 'pie',
                    radius: [20, 50],
                    // top: top + '%',
                    // height: '33.33%',
                    left: 'center',
                    width: 500,
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        alignTo: 'edge',
                        formatter: '{name|{b}} {time|{c} 人} \n {per|{d}%}',
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        fontSize: 14,
                        rich: {
                            per: {
                                padding: [10, 0, 0, 0],
                            },
                        }
                    },
                    labelLine: {
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: (params) => {
                        const myChart = this.$refs.centreLeft1ChartRef.chart
                        const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                        const points = params.labelLinePoints;
                        // Update the end point.
                        points[2][0] = isLeft
                          ? params.labelRect.x
                          : params.labelRect.x + params.labelRect.width;
                        return {
                          labelLinePoints: points
                        };
                    },
                    data: data
                }]
            }
        },
        getMineOption(data) {
            this.options1 = {
                title: {
                    text: "会员活跃趋势",
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: {
                    height: 270 // 设置图表的高度为200像素
                },
                color: ['#73c0de'],
                toolbox: {
                    top: 10,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: Object.keys(data),
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: [{
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} 人'
                    }
                }, ],
                series: [{
                        name: '活跃人数',
                        type: 'bar',
                        label: {
                            show: !0,
                        },
                        markLine: {
                            data: [{ type: 'average', name: '平均线' }]
                        },
                        data: Object.values(data)
                    },
                    // {
                    //     name: '活跃人数',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     smooth: !0,
                    //     symbolOffset: [0, -20],
                    //     symbolSize: 18,
                    //     tooltip: {
                    //         valueFormatter: function(value) {
                    //             return value + ' 人';
                    //         }
                    //     },
                    //     data: Object.values(data)
                    // }
                ]
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tNumber {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tNumber-box {
        height: 100%;
    }
    .prefix {
        height:1px;
        color: rgb(238,102,102);
        font-size:30px;
        margin-right: 12px;
    }
}
.store-list {
    // overflow-y: auto;
    overflow: hidden;
    font-weight: 0;
    color: #D8DEE9;
    position: relative;
    background: #0f0c28;
    .list {
        // padding: 10px;
        position: absolute;  
        top: 0;  
        left: 0;  
        margin: 0;  
        padding: 0;
        height: 890px;
        /* 这里不直接设置高度，而是由JavaScript动态计算并设置 */  
        animation: scrollUp 30s linear infinite; /* 假设滚动一次需要10秒 */ 
    }
    .item {
        // position: absolute;
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #787878;
        margin-bottom: 20px;
        line-height: 26px;
        .img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .addr {
            width: 600px;
            font-size: 20px; 
            margin-top: 10px; 
            text-overflow: ellipsis;
            overflow: hidden;
            white-space:nowrap;
        }
    }
}

@keyframes scrollUp {  
  0% {  
    transform: translateY(0);  
  }  
  100% {  
    /* 这里使用占位符值，因为具体值将由JavaScript计算 */  
    transform: translateY(-100%); /* 或者使用具体的px值 */  
  }  
}

</style>