<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>
<script>
// import tdTheme from './theme.json' // 引入默认主题
// import '../map/fujian.js'
// import { zsMap } from '@/utils/zhongshan'

export default {
    name: 'echart',
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '2.5rem'
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        options: {
            handler(options) {
                // 设置true清空echart缓存
                this.chart.setOption(options, true)
            },
            deep: true
        }
    },
    mounted() {
        // this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
        this.initChart();
    },
    beforeDestroy() {
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            // 初始化echart
            this.chart = this.$echarts.init(this.$el, 'dark')
            this.chart.setOption(this.options, true)
        }
    }
}
</script>
<style>
</style>