import axios from 'axios'
import errorCodes from '@/utils/errorCodes'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.NODE_ENV == 'production' ? 'https://zscr.com.cn' : '',
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
 
  config.params = config.params || {}
  var token = localStorage.getItem('wechat_access_token') || ''
  config.params['token'] = token
  // config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
  // const toast = Toast.loading({
  //   duration: 0, // 持续展示 toast
  //   forbidClick: true,
  //   message: '数据加载中...',
  // });

  return config
}, error => {
    console.log(error)
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // Toast.clear()
    // 未设置状态码则默认成功状态
    const code = res.data.code;
    // 获取错误信息
    const message = errorCodes[code] || res.data.msg || errorCodes['default']
    if (code === 401) {
        localStorage.clear()
        // Dialog.confirm({
        //     title: '系统提示',
        //     message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
        //     confirmButtonText: '重新登录'
        // }).then(() => {
        //     location.reload()
        // })
    } else if (code === 500) {
      // Toast.fail('系统异常！');
      return Promise.reject(new Error(message))
    } else if (code === 101) {
      localStorage.clear()
      // Dialog.alert({
      //     title: '系统提示',
      //     message: '登录状态已过期，请重新登录',
      //     confirmButtonText: '重新登录'
      // }).then(() => {
      //     // location.href = '/abc'
      //     location.reload()
      // })
      // Toast(message);
      // return Promise.reject({code, message})
    } else if (code !== 1) {
      // Dialog.alert({
      //   title: '系统提示',
      //   message: message,
      // }).then(() => {
      //   // on close
      // });
      return Promise.reject({code, message})
    } else {
      return res.data.data
    }
  },
  error => {
    // Toast.clear()
    // Toast({
    //     message: '网络错误，请联系管理员处理！',
    //     duration: 5 * 1000
    // });
    console.log(error)
    return Promise.reject(new Error('网络错误'))
  }
)

export default service
