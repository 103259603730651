<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <!-- <dv-full-screen-container class="bg"> -->
            <!-- <dv-loading v-if="loading">数据加载中...</dv-loading> -->
            <div class="host-body">
                <div class="d-flex jc-center">
                    <dv-decoration-10 class="dv-dec-10" />
                    <div class="d-flex jc-center">
                        <dv-decoration-8 class="dv-dec-8" />
                        <div class="title">
                            <span class="title-text">快帮平台数据大屏</span>
                            <dv-decoration-5 class="dv-dec-6" style="height: 30px;" />
                            <!-- <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" /> -->
                        </div>
                        <dv-decoration-8 class="dv-dec-8" :reverse="true"/>
                    </div>
                    <dv-decoration-10 class="dv-dec-10-s" />
                </div>
                <!-- 第二行 -->
                <div class="d-flex jc-between px-2">
                    <div class="d-flex aside-width">
                        <div class="react-left" @click="onShowInfo('ss')">
                            <span class="react-left" style="background:transparent;"></span>
                            <dv-border-box-8 v-if="active == 'ss'">
                                <span class="text">业务实时数据</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">业务实时数据</span>
                            </dv-border-box-7>
                        </div>
                        <div class="react-left ml-3" @click="onShowInfo('jk')">
                            <dv-border-box-8 v-if="active == 'jk'">
                                <span class="text">服务实时监控</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">服务实时监控</span>
                            </dv-border-box-7>
                        </div>
                        <!-- <div class="react-left ml-3" @click="onShowInfo('cw')">
                            <dv-border-box-8 v-if="active == 'cw'">
                                <span class="text">财务数据展示</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">财务数据展示</span>
                            </dv-border-box-7>
                        </div> -->
                        <div class="react-left ml-3" @click="onShowInfo('kh')">
                            <dv-border-box-8 v-if="active == 'kh'">
                                <span class="text">客户数据分析</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">客户数据分析</span>
                            </dv-border-box-7>
                        </div>
                    </div>
                    <div class="d-flex aside-width">
                        <div class="react-right mr-3" @click="onShowInfo('fw')">
                            <dv-border-box-8 v-if="active == 'fw'">
                                <span class="text">服务质量和效率</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">服务质量和效率</span>
                            </dv-border-box-7>
                        </div>
                        <div class="react-right mr-3" @click="onShowInfo('qs')">
                            <dv-border-box-8 v-if="active == 'qs'">
                                <span class="text">市场趋势和预测</span>
                            </dv-border-box-8>
                            <dv-border-box-7 v-else>
                                <span class="text">市场趋势和预测</span>
                            </dv-border-box-7>
                            <!-- <dv-border-box-8><span class="text fw-b"></span></dv-border-box-8> -->
                        </div>
                        <div class="react-right">
                            <span class="react-after" style="background:transparent;"></span>
                            <dv-border-box-7>
                            <span class="text" style="">
                                {{ dateYear }} {{ dateWeek }} {{ dateDay }}
                            </span>
                            </dv-border-box-7>
                        </div>
                    </div>
                </div>
                <div class="body-box" v-if="loading" style="display: flex;justify-content: center;align-items: center;height: 800px;">
                    <dv-decoration-12 style="width:200px;height:200px;">数据加载中</dv-decoration-12>
                </div>
                <RealTimeModule v-if="active == 'ss'" />

                <MonitorModule v-if="active == 'jk'" />

                <UserModule v-if="active == 'kh'" />

                <ServiceModule v-if="active == 'fw'" />

                <TrendModule v-if="active == 'qs'" />
            </div>
        </div>
    </div>
</template>
<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index'
import RealTimeModule from './realtime'
import UserModule from './user'
import ServiceModule from './service'
import TrendModule from './trend'
import MonitorModule from './monitor'
export default {
    mixins: [drawMixin],
    data() {
        return {
            active: 'ss',
            timing: null,
            loading: !1,
            dateYear: '',
            dateDay: '',
            dateWeek: '',
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        }
    },
    components: {
        RealTimeModule,
        UserModule,
        ServiceModule,
        TrendModule,
        MonitorModule
    },
    mounted() {
        this.timeFn()
        // this.cancelLoading()
        // this.autoSync()
    },
    beforeDestroy() {
        this.timing = null
        clearInterval(this.timing)
    },
    methods: {
        onShowInfo(active) {
            this.active = active
        },
        timeFn() {
            this.timing = null
            clearInterval(this.timing)
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]

                //每10分钟刷新一次数据
                let currentDate = new Date()
                let minutes = currentDate.getMinutes()
                let seconds = currentDate.getSeconds()
                if (minutes <= 0 && seconds <= 0) {
                    location.reload()
                }
                if (minutes % 10 === 0 && seconds <= 0) {
                    location.reload()
                }
            }, 1000)
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>