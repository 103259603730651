<template>
    <div class="body-box" v-if="!loading">
        <div class="left-box m-left">
            <div class="item" v-for="eng, idx in engs" :key="idx"
                @click="onShowOrder(eng.orders)">
                <dv-border-box-7 style="height: 80px;">
                    <div class="eng">
                        <div class="avatar">
                            <img :src="eng.img" />
                        </div>
                        <div class="info">
                            <div class="name">{{eng.alias}}</div>
                            <div class="addr">🌟：{{eng.star}}星</div>
                        </div>
                        <div class="status">
                            <dv-decoration-9 style="width:50px;height:50px;" v-if="eng.orders">
                                服务中
                            </dv-decoration-9>
                            <div v-else>待命中</div>
                            <!-- <dv-decoration-12 style="width:50px;height:50px;" v-else>
                                待命中
                            </dv-decoration-12> -->
                        </div>
                    </div>
                </dv-border-box-7>
            </div>
            
        </div>
        <div class="center-box">
            <div id="centerTop">
                <dv-border-box-7>
                    <div class="tNumber">
                        当前服务中订单分布
                        <!-- 当前服务中：<span class="prefix">{{marks.length}}</span>单 -->
                    </div>
                </dv-border-box-7>
            </div>
            <centerMap :data="areaData" @change="onDateChange" />
        </div>
        <div class="right-box">
            <orderArea :data="areaData"></orderArea>
            <!-- <div id="allmap" style="width: 300px; height: 300px;"></div> -->
        </div>
    </div>
</template>
<script>
import centerMap from './monitor/map'
import orderArea from './monitor/right'
// import rightArea from './monitor/right'
// import BMapGL from 'bmapgl'
// import { loadMap } from '../utils/index'

import { monitor } from '@/api/index'

export default {
    data() {
        return {
            loading: !0,
            areaData: [],
            marks: [],
            engs: [],
            orderId: 0,
        }
    },
    components: {
        centerMap,
        orderArea
    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            monitor({}, (res) => {
                this.engs = res.engs
                this.areaData = res.areaData
                this.loading = !1
            })
        },
        onShowOrder(id) {
            console.log(id)
            this.orderId = id
        },
        onDateChange() {
            this.$nextTick(() => {
                loadMap().then(res => {
                    console.log(res)
                })
            })
            // console.log(BMapGL)
            // const map = new BMapGL.Map('map'); // 创建Map实例
            // const point = new BMapGL.Point(116.404, 39.915); // 创建点坐标
            // map.centerAndZoom(point, 15); // 初始化地图,设置中心点坐标和地图级别
            // let map = new BMapGL.Map("allmap");
            // map.centerAndZoom(new BMapGL.Point(116.280190, 40.049191), 19);
            // map.enableScrollWheelZoom(true);
            // map.setHeading(64.5);
            // map.setTilt(73);
        },
        initMap() {
            let loadMap = new Promise(function(resolve, reject) {
                window.init = () => {
                    // eslint-disable-next-line
                    resolve(BMapGL)
                }
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.src = 'https://api.map.baidu.com/api?v=1.0&ak=ircfyV2JZI8bMLTucsvuopgUPc0HsIOx&callback=init'
                script.onerror = reject
                document.head.appendChild(script)
            })
            // loadMap.then((BMapGL) => {
            //     console.log('e33e333')
            // }).catch((err) => {
            //     console.log(11111)
            // })

            // 传入密钥获取地图回调。
            // loadMap().then((BMapGL) => {
            //     let map = new BMapGL.Map("allmap");
            //     console.log(map)
            //     map.centerAndZoom(new BMapGL.Point(116.280190, 40.049191), 19);
            //     map.enableScrollWheelZoom(true);
            //     map.setHeading(64.5);
            //     map.setTilt(73);

            //     // console.log(BMapGL.Map)
            //     // // 创建地图实例
            //     // let map = new BMapGL.Map("admap");
            //     // // 创建点坐标 axios => res 获取的初始化定位坐标
            //     // let point = new BMapGL.Point(114.031761, 22.542826)
            //     // // 初始化地图，设置中心点坐标和地图级别
            //     // map.centerAndZoom(point, 19)
            //     // //开启鼠标滚轮缩放
            //     // map.enableScrollWheelZoom(true)
            //     // map.setHeading(64.5)
            //     //map.setTilt(73)
            //     // 保存数据
            //     // this.myMap = map
            // }).catch((err)=>{
            //     console.log(11111)
            // })
    },
    }
}
</script>
<style lang="scss" scoped>
.body-box {
    grid-template-columns: 4fr 4fr 4fr !important;
    .left-box {
        max-height: 940px;
        overflow-y: auto;
        display: flex;
        align-item: center;
        flex-wrap: wrap;
        .item {
            padding: 0 10px; 
            width: 50%;
            height: 90px;
        }
        .eng {
            width: 300px;
            // width: 100%;
            display: flex;
            align-item: center;
            padding: 0 20px; 
            .avatar {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }
            .info {
                height: 100%;
                width: 55%;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: left;
                .name {
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .addr {
                    font-size: 18px;
                    color: #999;
                }
            }
            .status {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

#centerTop {
    .tNumber {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;

        .prefix {
            color: #3de7c9;
            font-size: 40px;
        }
    }
}
</style>

<style type="text/css">
    .m-left .dv-border-box-7 .border-box-content {
        display: flex!important;
    }
</style>