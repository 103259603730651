<template>
    <dv-border-box-7 style="margin-top: 10px;">
    <div id="centerLeft2" :class="{animation: animation}">
        <div class="d-flex jc-center">
            <div>
                <Echart id="centreLeft1Chart" 
                    ref="centreLeft1ChartRef" 
                    :options="options" height="820px" width="680px">
                </Echart>
            </div>
        </div>
    </div>
    </dv-border-box-7>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            animation: !1,
            show_mark: !1,
            items: [],
            area: '',
            ranking: {
                data: [],
            },
            preSelectMapIndex: 0,
            dataType: '',
        };
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
                this.setOptions(newData)
                // 重新选择区域
                // this.handleMapRandomSelect()
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {
        
    },
    methods: {
        setOptions(newData) {
            let marks = []
            newData.forEach((it) => {
                if (it.marks.length > 0) {
                    marks = marks.concat(it.marks)
                }
            })
            let points = []
            marks.forEach((it) => {
                points.push({
                    name: it.name,
                    coord: it.location, // 设置标记点的地理坐标
                    symbol: 'pin',
                })
                // points.push({
                //     coord: it.location, // 设置标记点的地理坐标
                //     symbol: 'image://' + it.image, // 使用图片的base64编码作为标记
                //     symbolSize: [20, 20], // 设置图片大小
                //     symbolOffset: [0, -30],
                // })
            })

            this.options = {
                showLegendSymbol: false,
                tooltip: {
                    trigger: 'item',
                    textStyle: {
                        fontSize: 14,
                        lineHeight: 22,
                    },
                    position: point => {
                        // 固定在顶部
                        return [point[0] + 50, point[1] - 20];
                    },
                    // 如果需要自定义 tooltip样式，需要使用formatter
                    formatter: ({seriesType, data, name}) => {
                        if (seriesType == 'map') {
                            return `
                                <div style="text-align:left;">${name}</div>
                                <div style=""> 服务中订单：${data.marks.length}单 </div>
                            `
                        }
                    }
                },
                visualMap: {
                    min: 0,
                    max: 10,
                    show: false,
                    seriesIndex: 0,
                    // 颜色
                    inRange: {
                        color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)']
                    },
                },
                // 底部背景
                geo: {
                    show: true,
                    aspectScale: 0.85, //长宽比
                    zoom: 1.2,
                    top: '10%',
                    left: '16%',
                    map: '中山市',
                    roam: false,
                    itemStyle: {
                        normal: {
                            areaColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(7,114,204, .8)',
                            shadowOffsetX: 5,
                            shadowOffsetY: 5,
                        },
                        emphasis: {
                            areaColor: '#00aeef',
                        },
                    },
                },
                series: [{
                        name: '相关指数',
                        type: 'map',
                        aspectScale: 0.85, //长宽比
                        zoom: 1.2,
                        mapType: '中山市', // 自定义扩展图表类型
                        top: '10%',
                        left: '16%',
                        itemStyle: {
                            normal: {
                                color: 'red',
                                areaColor: 'rgba(19,54,162, .5)',
                                borderColor: 'rgba(0,242,252,.3)',
                                borderWidth: 1,
                                shadowBlur: 7,
                                shadowColor: '#00f2fc',
                            },
                            emphasis: {
                                areaColor: '#4f7fff',
                                borderColor: 'rgba(0,242,252,.6)',
                                borderWidth: 2,
                                shadowBlur: 10,
                                // shadowColor: '#00f2fc',
                                shadowColor: 'yellow',
                            },
                        },
                        label: {
                            formatter: params => `${params.name}`,
                            show: true,
                            position: 'insideRight',
                            textStyle: {
                                fontSize: 14,
                                color: '#efefef',
                            },
                            emphasis: {
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                        },
                        data: newData,
                        // markPoint: {
                        //     itemStyle: {
                        //         borderColor: '#fff', // 设置边框颜色，用于模拟圆角效果
                        //         borderWidth: 2, // 设置边框宽度，用于模拟圆角效果
                        //         shadowBlur: 10, // 设置阴影模糊大小
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)', // 设置阴影颜色
                        //         shadowOffsetX: 0, // 设置阴影水平偏移量
                        //         shadowOffsetY: 0 // 设置阴影垂直偏移量
                        //     },
                        //     // symbolOffset: [0, 30],
                        //     data: points,
                        // }
                    },
                    {
                        name: '服务分布',
                        type: 'custom',
                        coordinateSystem: 'geo',
                        data: marks,
                        renderItem: (params, api) => {
                            let { location, image } = marks[params.dataIndex]
                            // let image = require(`@/assets/mark.png`)
                            return {
                                type: 'image',
                                name: 'marker',
                                style: {
                                    image,
                                    width: 40,
                                    height: 40,
                                    x: api.coord(location)[0],
                                    y: api.coord(location)[1],
                                },
                                enterFrom: {
                                    type: 'circle',
                                    y: -100,
                                    enterFrom: {
                                        // 淡入
                                        style: { opacity: 0 },
                                        // 从左飞入
                                        x: 0
                                    }
                                }
                            }
                        }
                    },
                ],
            };
        },
        // // 开启定时器
        // startInterval() {
        //     const _self = this;
        //     // 应通过接口获取配置时间，暂时写死5s
        //     const time = 5000;
        //     if (this.intervalId !== null) {
        //         clearInterval(this.intervalId);
        //     }
        //     this.intervalId = setInterval(() => {
        //         _self.reSelectMapRandomArea();
        //     }, time);
        // },
        // // 重新随机选中地图区域
        // reSelectMapRandomArea() {
        //     let { data } = this
        //     this.$nextTick(() => {
        //         try {
        //             const map = this.$refs.centreLeft1ChartRef.chart
        //             let index = this.preSelectMapIndex + 1
        //             if (index > (data.length - 1)) {
        //                 index = 0
        //             }
        //             map.dispatchAction({
        //                 type: 'unselect',
        //                 seriesIndex: 0,
        //                 dataIndex: this.preSelectMapIndex,
        //             });
        //             map.dispatchAction({
        //                 type: 'showTip',
        //                 seriesIndex: 0,
        //                 dataIndex: index,
        //             });
        //             map.dispatchAction({
        //                 type: 'select',
        //                 seriesIndex: 0,
        //                 dataIndex: index,
        //             });
        //             this.preSelectMapIndex = index;
        //         } catch (error) {
        //             console.log(error)
        //         }
        //     });
        // },
        // handleMapRandomSelect() {
        //     this.$nextTick(() => {
        //         try {
        //             const map = this.$refs.centreLeft2ChartRef.chart;
        //             const _self = this;
        //             setTimeout(() => {
        //                 _self.reSelectMapRandomArea();
        //             }, 0);
        //             // 移入区域，清除定时器、取消之前选中并选中当前
        //             map.on('mouseover', function(params) {
        //                 clearInterval(_self.intervalId);
        //                 map.dispatchAction({
        //                     type: 'unselect',
        //                     seriesIndex: 0,
        //                     dataIndex: _self.preSelectMapIndex,
        //                 });
        //                 map.dispatchAction({
        //                     type: 'select',
        //                     seriesIndex: 0,
        //                     dataIndex: params.dataIndex,
        //                 });
        //                 _self.preSelectMapIndex = params.dataIndex;
        //             });
        //             // 移出区域重新随机选中地图区域，并开启定时器
        //             map.on('globalout', function() {
        //                 _self.reSelectMapRandomArea();
        //                 _self.startInterval();
        //             });
        //             this.startInterval();
        //         } catch (error) {
        //             console.log(error)
        //         }
        //     });
        // },
    },
};
</script>
<style lang="scss" scoped>
.animation {
    transform: rotateY(180deg);
}
#centerLeft2 {
    $box-width: 780px;
    $box-height: 900px;
    padding: 16px;
    height: $box-height;
    // min-width: $box-width;
    border-radius: 5px;
    transition:all 1s;
    transform-style: preserve-3d;
    position: relative;

    .ranking {
        position: absolute;
        bottom: 85px;
        right: 40px;
    }
    .filter {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 350px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        z-index: 99;
        .item {
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #eee;
            font-size: 18px;
            // padding: 0 50px;
            width: 150px;
            margin-left: 20px;
            margin-bottom: 20px;
        }
    }
    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
        position: relative;
        .roll-back {
            position: absolute;
            right: 10px;
            top: 0px;
            height: 65px;
            width: 200px;
            align-items: center;
            justify-content: flex-end;
            z-index: 1;
        }
    }
    .front, .back {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .back {
        transform: rotateY(180deg);
    }

    .text {
        color: #c3cbde;
    }
    .ctitle {
        font-size: 16px;
    } 
    .dv-dec-1 {
        position: relative;
        width: 100px;
        height: 20px;
        top: -3px;
    }

    .chart-box {
        margin-top: 16px;
        width: 170px;
        height: 170px;

        .active-ring-name {
            padding-top: 10px;
        }
    }
}
</style>
<style type="text/css">
    #centerLeft2 .dv-border-box-11 .border-box-content {
        color: #46bd87;
        font-weight: 700;
        padding: 80px 30px 30px;
    }
    #centerLeft2 .dv-border-box-12 .border-box-content {
        padding: 30px;
    }
</style>