<template>
    <div class="leftArea">
        <Echart id="centreLeft1Chart" ref="centreLeft1ChartRef" :options="options" height="990px"></Echart>
        <!-- <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="495px"></Echart> -->
        <!-- <dv-capsule-chart :config="config[0]" style="width: 80%;height: 400px;" /> -->
        <!-- <dv-capsule-chart :config="config[1]" style="width: 80%;height: 590px;"/> -->
        <!-- <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options1" height="490px"></Echart> -->
    </div>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            options1: {},
        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        sdata: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
                this.options = this.setOption(newData)
                // this.options1 = this.setOption(b)
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        },
    },
    mounted() {

    },
    methods: {
        setOption(newData) {
            let title = []
            let series = []
            let xAxis = []
            let yAxis = []
            let leftMap = [25, 75, 25, 75, 25, 75]
            let topMap = [8, 8, 40, 40, 70, 70]
            newData.map((data, idx) => {
                title.push({
                    subtext: data.name,
                    left: `${leftMap[idx]}%`,
                    top: `${topMap[idx] - 7}%`,
                    textAlign: 'center',
                    subtextStyle: {
                        color: '#bbb',
                        fontWeight: 'normal',
                        fontSize: 18
                    }
                })
                let list = data.list
                xAxis.push({ 
                    gridIndex: idx, 
                    type: 'category', 
                    data: list.map(item => item.name),
                    axisLabel: { interval: 0 }, //{ interval: 0, rotate: 30 }
                })
                yAxis.push({ gridIndex: idx, type: 'value'})

                series.push({
                    name: data.name,
                    type: 'bar',
                    xAxisIndex: idx,
                    yAxisIndex: idx,
                    data: list.map(item => item.value),
                    smooth: !0,
                    label: {
                        show: !0,
                    },
                })
            })
            return {
                title,
                // title: {
                //     text: "会员增长趋势",
                //     left: 'center',
                //     top: 0,
                // },
                // toolbox: {
                //     feature: {
                //         dataView: { show: true, readOnly: false },
                //         magicType: { show: true, type: ['line', 'bar'] },
                //         saveAsImage: { show: true },
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: [
                    { left: '10%', top: '6%', width: '38%', height: '22%' },
                    { right: '5%', top: '6%', width: '38%', height: '22%' },
                    { left: '10%', top: '38%', width: '38%', height: '22%' },
                    { right: '5%', top: '38%', width: '38%', height: '22%' },
                    { left: '10%', bottom: '8%', width: '38%', height: '22%' },
                    { right: '5%', bottom: '8%', width: '38%', height: '22%' }
                ],
                xAxis: xAxis,
                yAxis: yAxis,
                series: series,
            }
        },
        setMoreOption(newData) {
            let title = []
            let series = []

            newData.map((data, idx) => {
                this.config.push({
                    data: data.list,
                    showValue: !0,
                })

                let top = idx ? 68 : 18
                let left = 50
                // let m = Math.ceil((idx + 1) % 2) ? 1 : 3
                // let left = 25 * m
                // let n = Math.ceil((idx + 1) / 2) - 1
                // let map = [14, 38, 62, 86]
                // let top = map[n]
                title.push({
                    subtext: data.name,
                    left: `${left}%`,
                    top: `${top - 16}%`,
                    textAlign: 'center',
                    subtextStyle: {
                        color: '#999',
                        fontWeight: 'normal',
                        fontSize: 18
                    }
                })
                series.push({
                    type: 'pie',
                    radius: [30, 100],
                    center: [`${left}%`, `${top}%`],
                    // top: top + '%',
                    // height: '20%',
                    // left: 'center',
                    // width: 400,
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        formatter: '{name|{b}}\n {per|{d}%}',
                        alignTo: 'labelLine',
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        rich: {
                            per: {
                                fontSize: 14,
                                color: '#999'
                            }
                        }
                    },
                    data: data.list
                })
            })
            this.options1 = {
                title: title,
                series: series,
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.leftArea {
    position: relative;
    height: 100%;
    background: #0F0C28;
}
</style>