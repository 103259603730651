import request from '@/utils/request'

// 查询列表
export function home(query, success, fail) {
    request({
        url: '/api/auth/bigData',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

// 查询列表
export function ss(query, success, fail) {
    request({
        url: '/mdata',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

export function user(query, success, fail) {
    request({
        url: '/mdata/user',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

export function service(query, success, fail) {
    request({
        url: '/mdata/service',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

export function trend(query, success, fail) {
    request({
        url: '/mdata/trend',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

export function monitor(query, success, fail) {
    request({
        url: '/mdata/monitor',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}

export function getOrder(query, success, fail) {
    request({
        url: '/mdata/monitor/getOrder',
        method: 'get',
        params: query
    }).then(res => {
        success && success(res)
    }).catch(err => {
        console.log(err.message)
        fail && fail(err)
    });
}