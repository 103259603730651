<template>
    <dv-border-box-7 style="margin-top: 10px;">
    <div id="centerLeft2" :class="{animation: animation}">
        <div class="d-flex jc-center">
            <div>
                <Echart id="centreLeft1Chart" 
                    ref="centreLeft1ChartRef" 
                    :options="options" height="820px" width="680px">
                </Echart>
                <!-- dev开启后可以地图选点 -->
                <!-- <dv-flyline-chart-enhanced :config="config" :dev="true" style="width:760px;height:820px;background: transparent;position: absolute;top: 0;left: 0;z-index: 2;" /> -->
                <div class="ranking">
                    <div style="text-align: center;margin-bottom: 10px;">热门镇区前5名</div>
                    <dv-capsule-chart :config="ranking" style="width:300px;height:200px" />
                    <!-- <dv-scroll-ranking-board :config="ranking" style="width:300px;height:200px;" /> -->
                </div>
                <div class="filter">
                    <div class="item" @click="onChangeData('')">
                        <dv-border-box-8 v-if="!dataType">全 部</dv-border-box-8>
                        <dv-border-box-7 v-else>全 部</dv-border-box-7>
                    </div>
                    <div class="item" @click="onChangeData('year')">
                        <dv-border-box-8 v-if="dataType == 'year'">近一年</dv-border-box-8>
                        <dv-border-box-7 v-else>近一年</dv-border-box-7>
                    </div>
                    <div class="item" @click="onChangeData('month')">
                        <dv-border-box-8 v-if="dataType == 'month'">近一月</dv-border-box-8>
                        <dv-border-box-7 v-else>近一月</dv-border-box-7>
                    </div>
                    <div class="item" @click="onChangeData('week')">
                        <dv-border-box-8 v-if="dataType == 'week'">近一周</dv-border-box-8>
                        <dv-border-box-7 v-else>近一周</dv-border-box-7>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </dv-border-box-7>
</template>
<script>
import Echart from '@/common/echart';
export default {
    components: {
        Echart
    },
    data() {
        return {
            options: {},
            animation: !1,
            show_mark: !0,
            items: [],
            area: '',
            config: {
                points: [
                    {
                        name: '东区',coordinate: [0.45, 0.49],
                        halo: {show: true},
                        text: {show: false}
                    },
                    {name: '五桂山',coordinate: [0.45, 0.65],text: {show: false}},
                    {name: '三乡',coordinate: [0.48, 0.78],text: {show: false},halo: {show: true,color: '#8378ea'},},
                    {name: '坦洲',coordinate: [0.48, 0.91],text: {show: false},halo: {show: true,color: '#8378ea'},},
                    {name: '神湾',coordinate: [0.35, 0.85],text: {show: false}},
                    {name: '板芙',coordinate: [0.30, 0.70],text: {show: false},halo: {show: true,color: '#8378ea'},},
                    {name: '南区',coordinate: [0.33, 0.59],text: {show: false}},
                    {name: '大涌',coordinate: [0.23, 0.59],text: {show: false}},
                    {name: '南朗镇',coordinate: [0.70, 0.53],text: {show: false},halo: {show: true,color: '#8378ea'},},
                    {name: '火炬开发区',coordinate: [0.50, 0.44],text: {show: false}},
                    {
                        name: '石岐区', coordinate: [0.40, 0.43],
                        text: {show: false},
                        icon: {
                            src: require('@/assets/mapCenterPoint.png'),
                            width: 30,
                            height: 30
                        },
                    },
                    {name: '西区',coordinate: [0.35, 0.46],text: {show: false}},
                    {name: '沙溪',coordinate: [0.29, 0.50],text: {show: false}},
                    {name: '横栏',coordinate: [0.18, 0.47],text: {show: false}},
                    {name: '东升镇',coordinate: [0.30, 0.34],text: {show: false}},
                    {name: '港口',coordinate: [0.42, 0.32],text: {show: false}},
                    {name: '民众',coordinate: [0.59, 0.32],text: {show: false},halo: {show: true,color: '#8378ea'}},
                    {name: '三角',coordinate: [0.47, 0.22],text: {show: false}},
                    {name: '阜沙',coordinate: [0.35, 0.22],text: {show: false}},
                    {name: '黄圃',coordinate: [0.39, 0.16],text: {show: false},halo: {show: true,color: '#8378ea'}},
                    {name: '东凤',coordinate: [0.25, 0.22],text: {show: false}},
                    {name: '南头',coordinate: [0.27, 0.16],text: {show: false}},
                    {name: '小榄',coordinate: [0.18, 0.29],text: {show: false}},
                    {name: '古镇',coordinate: [0.10, 0.33],text: {show: false},halo: {show: true,color: '#8378ea'},}
                  ],
                  lines: [
                    {source: '东凤',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '古镇',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '小榄',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '黄圃',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '阜沙',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '三角',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '民众',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '港口',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '东升镇',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '横栏',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '西区',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '石岐区',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '火炬开发区',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '南朗镇',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '大涌',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '南区',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '板芙',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '坦洲',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '三乡',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '五桂山',target: '石岐区',color: '#fb7293',width: 2},
                    {source: '南头',target: '石岐区',color: '#fb7293',width: 4},
                    {source: '神湾',target: '石岐区',color: '#fb7293',width: 4},
                  ],
                  icon: {
                    show: true,
                    src: require('@/assets/mapPoint.png'),
                  },
                  text: {
                    show: true,
                  },
                  k: 0.5,
                  bgImgSrc: ''
            },
            ranking: {
                data: [],
            },
            preSelectMapIndex: 0,
            dataType: '',
        };
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data: {
            handler(newData) {
                this.ranking = {
                    data: newData.slice(0, 5),
                    showValue: !0,
                }
                this.setOptions(newData)
                // 重新选择区域
                this.handleMapRandomSelect()
            },
            immediate: true,
            // 开启深度监听 deep
            // deep: true
        }
    },
    mounted() {

    },
    methods: {
        onChangeData(type) {
            // let { data } = this
            this.dataType = type
            this.$emit('change', type)
            // this.setOptions(data[index])
        },
        setOptions(data) {
            this.options = {
                showLegendSymbol: false,
                tooltip: {
                    trigger: 'item',
                    fontSize: 14,
                    lineHeight: 22,
                    position: point => {
                        // 固定在顶部
                        return [point[0] + 50, point[1] - 20];
                    },
                    // 如果需要自定义 tooltip样式，需要使用formatter
                    formatter: ({seriesType, data, name}) => {
                        if (seriesType == 'map') {
                            return `
                                <div style="text-align:left;">${name}</div>
                                <div>客户总数：共 <span style="font-size: 16px;">${data.value} </span>人</div>
                                <div>消费金额：共 <span style="color: green;font-size: 16px;">${data.amount} </span>元</div>
                                <div>区域排行：第<span style="color: red;font-size: 18px;"> ${data.rank} </span>名</div>
                            `
                        }
                    }
                },
                visualMap: {
                    min: 0,
                    max: data[0].value,
                    show: true,
                    seriesIndex: 0,
                    calculable: true,
                    inRange: {
                      color: ['rgb(115,192,222)', 'rgb(84,112,198)', 'rgb(238,102,102)']
                    },
                    // left: auto,
                    // right: 0,
                },
                // 底部背景
                geo: {
                    show: true,
                    aspectScale: 0.85, //长宽比
                    zoom: 1.1,
                    top: '10%',
                    left: '6%',
                    map: '中山市',
                    roam: false,
                },
                series: [{
                        data,
                        name: '相关指数',
                        type: 'map',
                        aspectScale: 0.85, //长宽比
                        zoom: 1.1,
                        map: '中山市', // 自定义扩展图表类型
                        top: '10%',
                        left: '6%',
                        label: {
                            // formatter: '{b}: {@value}',
                            // formatter: '{b}: {value|{@value}}',
                            // formatter: params => `${params.name}: ${params.value | 0}`,
                            // formatter: params => `${params.name}`,
                            formatter: params => `${params.name}\n${params.value | 0}人`,
                            show: true,
                            position: 'insideRight',
                            fontSize: 16,
                            color: '#333',
                            // rich: {
                            //     value: {
                            //         color: 'rgb(238,102,102)',
                            //         fontSize: 18,
                            //     }
                            // },
                        },
                    },
                ],
            }
        },
        // 开启定时器
        startInterval() {
            const _self = this;
            // 应通过接口获取配置时间，暂时写死5s
            const time = 5000;
            if (this.intervalId !== null) {
                clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
                _self.reSelectMapRandomArea();
            }, time);
        },
        // 重新随机选中地图区域
        reSelectMapRandomArea() {
            let { data } = this
            this.$nextTick(() => {
                try {
                    const map = this.$refs.centreLeft1ChartRef.chart
                    let index = this.preSelectMapIndex + 1
                    if (index > (data.length - 1)) {
                        index = 0
                    }
                    map.dispatchAction({
                        type: 'unselect',
                        seriesIndex: 0,
                        dataIndex: this.preSelectMapIndex,
                    });
                    map.dispatchAction({
                        type: 'showTip',
                        seriesIndex: 0,
                        dataIndex: index,
                    });
                    map.dispatchAction({
                        type: 'select',
                        seriesIndex: 0,
                        dataIndex: index,
                    });
                    this.preSelectMapIndex = index;
                } catch (error) {
                    console.log(error)
                }
            });
        },
        handleMapRandomSelect() {
            this.$nextTick(() => {
                try {
                    const map = this.$refs.centreLeft1ChartRef.chart;
                    const _self = this;
                    setTimeout(() => {
                        _self.reSelectMapRandomArea();
                    }, 0);
                    // 移入区域，清除定时器、取消之前选中并选中当前
                    map.on('mouseover', function(params) {
                        clearInterval(_self.intervalId);
                        map.dispatchAction({
                            type: 'unselect',
                            seriesIndex: 0,
                            dataIndex: _self.preSelectMapIndex,
                        });
                        map.dispatchAction({
                            type: 'select',
                            seriesIndex: 0,
                            dataIndex: params.dataIndex,
                        });
                        _self.preSelectMapIndex = params.dataIndex;
                    });
                    // 移出区域重新随机选中地图区域，并开启定时器
                    map.on('globalout', function() {
                        _self.reSelectMapRandomArea();
                        _self.startInterval();
                    });
                    this.startInterval();
                } catch (error) {
                    console.log(error)
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.animation {
    transform: rotateY(180deg);
}
#centerLeft2 {
    $box-width: 780px;
    $box-height: 900px;
    padding: 16px;
    height: $box-height;
    // min-width: $box-width;
    border-radius: 5px;
    transition:all 1s;
    transform-style: preserve-3d;
    position: relative;

    .ranking {
        position: absolute;
        bottom: 85px;
        right: 40px;
    }
    .filter {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 350px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        z-index: 99;
        .item {
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #eee;
            font-size: 18px;
            // padding: 0 50px;
            width: 150px;
            margin-left: 20px;
            margin-bottom: 20px;
        }
    }
    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
        position: relative;
        .roll-back {
            position: absolute;
            right: 10px;
            top: 0px;
            height: 65px;
            width: 200px;
            align-items: center;
            justify-content: flex-end;
            z-index: 1;
        }
    }
    .front, .back {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .back {
        transform: rotateY(180deg);
    }

    .text {
        color: #c3cbde;
    }
    .ctitle {
        font-size: 16px;
    } 
    .dv-dec-1 {
        position: relative;
        width: 100px;
        height: 20px;
        top: -3px;
    }

    .chart-box {
        margin-top: 16px;
        width: 170px;
        height: 170px;

        .active-ring-name {
            padding-top: 10px;
        }
    }
}
</style>
<style type="text/css">
    #centerLeft2 .dv-border-box-11 .border-box-content {
        color: #46bd87;
        font-weight: 700;
        padding: 80px 30px 30px;
    }
    #centerLeft2 .dv-border-box-12 .border-box-content {
        padding: 30px;
    }
</style>